import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'YMNaL4jU3LipVcKbDnobcUYIG7g7m80kXtVs',
  sdkSecret: 'okO5ee0ZcaA2M7gcuKIR28VbTfQISdzJAPeZ',
  topic: 'test117',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: 'samTesXpw1',
  signature: '',
};
